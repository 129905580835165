import { TextField, ListProps, ReferenceInput, useResourceContext } from 'react-admin';

import {
  PhoneField,
  TextInput,
  Grid,
  GridList,
  BooleanField,
  SelectInput,
  EmailField,
  Forbidden,
  DateField,
  DateRangeInput,
} from '@components';
import { genderOptions } from '@types';
import { checkSuperadmin } from '@providers';
import { useExporter, useRbacList } from '@hooks';
import { ManagerLoginBtn } from '../ManagerLoginBtn';
import { useDealerReference } from '@pages/manager/use-dealer-reference';
import cls from './ManagerList.module.css';

export const ManagerList = (props: ListProps) => {
  const isSuperadmin = checkSuperadmin();
  const rbacList = useRbacList();
  const dealerReference = useDealerReference();
  const resource = useResourceContext();

  const buyerName = resource === 'manager' ? 'Buyer'
    : resource === 'manager-retailer' ? 'Retailer' : 'Company name';
  const companyTitle = resource === 'manager' ? 'Buyer'
    : resource === 'manager-retailer' ? 'Retailer' : 'Associate';

  const exporter = useExporter({
    filename: `Verqor ${companyTitle}`,
    props: {
      'dealer.name': {
        label: buyerName,
        prop: 'dealer.name',
      },
      'name': {
        label: 'User name',
      },
      'email': {
        label: 'Email',
      },
      'phone': {
        label: 'Phone',
        transform: 'phone',
      },
      'gender': {
        label: 'Gender',
      },
      'position': {
        label: 'Position',
      },
      'associate.name': {
        label: 'Associate name',
        prop: 'associate.name',
      },
      'isEnabled': {
        label: 'Enabled',
        transform: 'boolean',
      },
      'isSuperadmin': {
        label: 'Supervisor',
        transform: 'boolean',
      },
      'createdAt': {
        label: 'Registered at',
        transform: 'date',
      },
      'typeOfManager': {
        label: 'Type of Manager',
        hide: resource !== 'manager',
      },
    },
  });

  if (!rbacList) {
    return <Forbidden />;
  }

  const filters = [
    <TextInput source="q" key="q" variant="outlined" label="Search" style={{ width: 300 }} inputProps={{ maxLength: 40 }} alwaysOn />,
    <ReferenceInput
      source="buyerId"
      name="buyerId"
      key="buyerId"
      reference={dealerReference}
      label={buyerName}
      allowEmpty
      alwaysOn
      perPage={1000}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText="name" label={buyerName} className={cls.refSelect}/>
    </ReferenceInput>,
    <SelectInput alwaysOn key="gender" source="gender" choices={genderOptions} />,
    <DateRangeInput
      key="createdAt"
      source="createdAt"
      label="Registration Date"
      defaultValue={false}
      alwaysOn
    />,
  ];

  return (
    <GridList {...props}
      filters={filters}
      sort={{ field: 'id', order: 'DESC' }}
      className={cls.list}
      exporter={exporter}
    >
      <Grid
        rowClick="edit"
        defaultColumns={'dealer.name associate.name name email phone position isEnabled isSuperadmin createdAt'.split(' ')}
        className={cls.grid}
      >
        <TextField source="dealer.name" label={buyerName} />
        <TextField source="name" label="User name" />
        <EmailField source="email" wrap />
        <PhoneField source="phone" />
        <TextField source="gender" />
        {resource === 'manager' && <TextField source="typeOfManager" label="Type of Manager" />}
        <TextField source="position" />
        <TextField source="associate.name" label="Associate name" />
        <BooleanField source="isEnabled" label="Enabled" />
        <BooleanField source="isSuperadmin" label="Supervisor" />
        {isSuperadmin && <ManagerLoginBtn source="login" isSmall color="#74706B" />}
        <DateField source="createdAt" label="Registered at" />
      </Grid>
    </GridList>
  );
};
